import { $authHost, $host } from "./index";
import { jwtDecode } from "jwt-decode";

export const login = async (name, password) => {
  const { data } = await $host.post("api/user/login", { name, password });
  localStorage.setItem("token", data.token);
  console.log(jwtDecode(data.token));
  return jwtDecode(data.token);
};

export const check = async () => {
  const { data } = await $authHost.get("api/user/auth", jwtDecode(localStorage.getItem("token")));
  localStorage.setItem("token", data.token);
  return jwtDecode(data.token);
};
