import React, { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import { API_PHOTO } from "../utils/consts";
import Form from "react-bootstrap/Form";
import { observer } from "mobx-react-lite";
import { deleteTeamAPI, fetchTeam, updateTeamAPI } from "../http/teamAPI";
import { Context } from "../../index.js";

const PopupTeam = observer((props) => {
  const [first_name, setFirst_name] = useState(props.info.first_name);
  const [last_name, setLast_name] = useState(props.info.last_name);
  const [role, setRole] = useState(props.info.role);
  const [experience, setExperience] = useState(props.info.experience);
  const [courses, setCourses] = useState(props.info.courses);
  const [interesting, setInteresting] = useState(props.info.interesting);
  const [strongest_skill, setStrongest_skill] = useState(props.info.strongest_skill);
  const [language, setLanguage] = useState(props.info.language);

  const { teamPortfolio } = useContext(Context);

  const updateTeam = async () => {
    const formData = new FormData();
    formData.append("id", props.info.id);
    formData.append("first_name", first_name);
    formData.append("last_name", last_name);
    formData.append("role", role);
    formData.append("experience", experience);
    formData.append("courses", courses);
    formData.append("interesting", interesting);
    formData.append("strongest_skill", strongest_skill);
    formData.append("language", language);

    if (
      !props.info.id ||
      !first_name ||
      !last_name ||
      !role ||
      !experience ||
      !courses ||
      !interesting ||
      !strongest_skill ||
      !language
    ) {
      window.alert("Залишати пусті поля не можна");
    } else {
      const confirmDel = window.confirm("Точно бажаєте зберегти зміни?");
      if (confirmDel) {
        await updateTeamAPI(formData).then(() => {
          teamPortfolio.setReloadTeam(!teamPortfolio.reloadTeam);
        });
      }
    }
  };

  const deleteTeam = async () => {
    const confirmDel = window.confirm("Ви дійсно хочете видалити учасника команди?");
    if (confirmDel) {
      await deleteTeamAPI(props.info.id);
      await fetchTeam().then((data) => teamPortfolio.setTeam(data));
      props.onHide();
    }
  };

  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" size="lg">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Учасник команди</Modal.Title>
      </Modal.Header>
      <Modal.Body className="grid-example">
        <Container>
          <Row style={{ maxHeight: "300px" }}>
            <Col xs={12} md={3}>
              <Image
                style={{
                  width: "150px",
                  height: "200px",
                }}
                src={API_PHOTO + props.info.img}
              />
            </Col>
            <Col xs={6} md={8}>
              <Row style={{ maxHeight: "50px" }}>
                <Col xs={6} md={6}>
                  <Form.Control
                    className="mt-2"
                    type="text"
                    value={first_name}
                    onChange={(e) => setFirst_name(e.target.value)}
                  />
                </Col>
                <Col xs={6} md={6}>
                  <Form.Control
                    className="mt-2"
                    type="text"
                    value={last_name}
                    onChange={(e) => setLast_name(e.target.value)}
                  />
                </Col>
              </Row>
              <Row style={{ maxHeight: "50px" }}>
                <Col md={6}>
                  <Form.Control className="mt-2" type="text" value={role} onChange={(e) => setRole(e.target.value)} />
                </Col>
                <Col md={2}>
                  <Form.Control
                    className="mt-2"
                    type="text"
                    value={experience}
                    onChange={(e) => setExperience(e.target.value)}
                  />
                </Col>
                <Col md={2}>
                  <Form.Control
                    className="mt-2"
                    type="text"
                    value={language}
                    onChange={(e) => setLanguage(e.target.value)}
                  />
                </Col>
              </Row>
              <Form.Control className="mt-2" type="text" value={courses} onChange={(e) => setCourses(e.target.value)} />
              <Form.Control
                className="mt-2"
                type="text"
                value={interesting}
                onChange={(e) => setInteresting(e.target.value)}
              />
              <Form.Control
                className="mt-2"
                type="text"
                value={strongest_skill}
                onChange={(e) => setStrongest_skill(e.target.value)}
              />
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={() => deleteTeam()}>
          Видалити
        </Button>
        <Button variant="warning" onClick={() => updateTeam()}>
          Зберегти зміни
        </Button>
        <Button variant="success" onClick={props.onHide}>
          Вийти
        </Button>
      </Modal.Footer>
    </Modal>
  );
});
export default PopupTeam;
