import React, { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import { API_PHOTO } from "../utils/consts";
import Form from "react-bootstrap/Form";
import { observer } from "mobx-react-lite";
import { createTeamAPI, deleteTeamAPI, fetchTeam, updateTeamAPI } from "../http/teamAPI";
import { useNavigate } from "react-router-dom";
import { Context } from "../../index.js";

const PopupCreateTeam = observer((props) => {
  const [selectLang, setSelectLang] = useState(true);

  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [role, setRole] = useState("");
  const [experience, setExperience] = useState("");
  const [courses, setCourses] = useState("");
  const [interesting, setInteresting] = useState("");
  const [strongest_skill, setStrongest_skill] = useState("");
  const [file, setFile] = useState(null);
  const [language, setLanguage] = useState("ua");

  const [first_nameEn, setFirst_nameEn] = useState("");
  const [last_nameEn, setLast_nameEn] = useState("");
  const [roleEn, setRoleEn] = useState("");
  const [experienceEn, setExperienceEn] = useState("");
  const [coursesEn, setCoursesEn] = useState("");
  const [interestingEn, setInterestingEn] = useState("");
  const [strongest_skillEn, setStrongest_skillEn] = useState("");

  const { teamPortfolio } = useContext(Context);

  const selectFile = (e) => {
    setFile(e.target.files[0]);
  };

  const createTeam = async () => {
    const formData = new FormData();
    formData.append("first_name", first_name);
    formData.append("last_name", last_name);
    formData.append("role", role);
    formData.append("experience", experience);
    formData.append("courses", courses);
    formData.append("interesting", interesting);
    formData.append("strongest_skill", strongest_skill);
    formData.append("img", file);
    formData.append("language", "ua");

    const formDataEn = new FormData();
    formDataEn.append("first_name", first_nameEn);
    formDataEn.append("last_name", last_nameEn);
    formDataEn.append("role", roleEn);
    formDataEn.append("experience", experienceEn);
    formDataEn.append("courses", coursesEn);
    formDataEn.append("interesting", interestingEn);
    formDataEn.append("strongest_skill", strongest_skillEn);
    formDataEn.append("img", file);
    formDataEn.append("language", "en");

    if (
      !file ||
      !first_name ||
      !last_name ||
      !role ||
      !experience ||
      !courses ||
      !interesting ||
      !strongest_skill ||
      !first_nameEn ||
      !last_nameEn ||
      !roleEn ||
      !experienceEn ||
      !coursesEn ||
      !interestingEn ||
      !strongest_skillEn
    ) {
      window.alert("Залишати пусті поля не можна (заповніть всі поля українською та англійською)");
    } else {
      console.log("createTeam");
      props.onHide();

      await createTeamAPI(formData).then(() => {
        createTeamAPI(formDataEn).then(() => {
          teamPortfolio.setReloadTeam(!teamPortfolio.reloadTeam);
        });
      });
    }
  };

  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" size="lg">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {selectLang ? "Форма для нового співробітника (українською)" : "Форма для нового співробітника (англійською)"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="grid-example">
        <Container>
          <Row style={{ maxHeight: "300px" }}>
            <Col xs={12} md={3}>
              <Form.Control
                size="lg"
                className="mt-3"
                style={{
                  width: "150px",
                  height: "100px",
                }}
                type="file"
                onChange={selectFile}
              />
            </Col>
            {selectLang ? (
              <Col xs={6} md={8}>
                <Row style={{ maxHeight: "50px" }}>
                  <Col xs={6} md={6}>
                    <Form.Control
                      className="mt-2"
                      type="text"
                      placeholder="Ім'я"
                      value={first_name}
                      onChange={(e) => setFirst_name(e.target.value)}
                    />
                  </Col>
                  <Col xs={6} md={6}>
                    <Form.Control
                      className="mt-2"
                      type="text"
                      placeholder="Прізвище"
                      value={last_name}
                      onChange={(e) => setLast_name(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row style={{ maxHeight: "50px" }}>
                  <Col xs={6} md={6}>
                    <Form.Control
                      className="mt-2"
                      type="text"
                      placeholder="Посада"
                      value={role}
                      onChange={(e) => setRole(e.target.value)}
                    />
                  </Col>
                  <Col xs={6} md={6}>
                    <Form.Control
                      className="mt-2"
                      type="text"
                      placeholder="Досвід"
                      value={experience}
                      onChange={(e) => setExperience(e.target.value)}
                    />
                  </Col>
                </Row>
                <Form.Control
                  className="mt-2"
                  type="text"
                  placeholder="Пройдені курси"
                  value={courses}
                  onChange={(e) => setCourses(e.target.value)}
                />
                <Form.Control
                  className="mt-2"
                  type="text"
                  placeholder="Цікаве"
                  value={interesting}
                  onChange={(e) => setInteresting(e.target.value)}
                />
                <Form.Control
                  className="mt-2"
                  type="text"
                  placeholder="Найсильніша навичка"
                  value={strongest_skill}
                  onChange={(e) => setStrongest_skill(e.target.value)}
                />
              </Col>
            ) : (
              // Заповнення анкети англійською
              <Col xs={6} md={8}>
                <Row style={{ maxHeight: "50px" }}>
                  <Col xs={6} md={6}>
                    <Form.Control
                      className="mt-2"
                      type="text"
                      placeholder="First name"
                      value={first_nameEn}
                      onChange={(e) => setFirst_nameEn(e.target.value)}
                    />
                  </Col>
                  <Col xs={6} md={6}>
                    <Form.Control
                      className="mt-2"
                      type="text"
                      placeholder="Last name"
                      value={last_nameEn}
                      onChange={(e) => setLast_nameEn(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row style={{ maxHeight: "50px" }}>
                  <Col xs={6} md={6}>
                    <Form.Control
                      className="mt-2"
                      type="text"
                      placeholder="Role"
                      value={roleEn}
                      onChange={(e) => setRoleEn(e.target.value)}
                    />
                  </Col>
                  <Col xs={6} md={6}>
                    <Form.Control
                      className="mt-2"
                      type="text"
                      placeholder="Experience"
                      value={experienceEn}
                      onChange={(e) => setExperienceEn(e.target.value)}
                    />
                  </Col>
                </Row>
                <Form.Control
                  className="mt-2"
                  type="text"
                  placeholder="Courses"
                  value={coursesEn}
                  onChange={(e) => setCoursesEn(e.target.value)}
                />
                <Form.Control
                  className="mt-2"
                  type="text"
                  placeholder="Interesting"
                  value={interestingEn}
                  onChange={(e) => setInterestingEn(e.target.value)}
                />
                <Form.Control
                  className="mt-2"
                  type="text"
                  placeholder="Strongest skill"
                  value={strongest_skillEn}
                  onChange={(e) => setStrongest_skillEn(e.target.value)}
                />
              </Col>
            )}
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setSelectLang(!selectLang)}>
          {selectLang ? "Заповнити англійською" : "Заповнити українською"}
        </Button>
        <Button variant="success" onClick={() => createTeam()}>
          Додати нового співробітника
        </Button>
        <Button variant="danger" onClick={props.onHide}>
          Вийти
        </Button>
      </Modal.Footer>
    </Modal>
  );
});
export default PopupCreateTeam;
