import { $authHost, $host } from "./index";

export const fetchTeam = async () => {
  const { data } = await $host.get("api/team");
  return data;
};

export const deleteTeamAPI = async (id) => {
  const { data } = await $authHost.delete("api/team/delete/" + id);
  return data;
};

export const updateTeamAPI = async (updateInfo) => {
  const { data } = await $authHost.put("/api/team/update", updateInfo);
  return data;
};

export const createTeamAPI = async (info) => {
  const { data } = await $authHost.post("/api/team/create", info);
  return data;
};
