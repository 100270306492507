import React, { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import { API_PHOTO } from "../utils/consts";
import Form from "react-bootstrap/Form";
import { observer } from "mobx-react-lite";
import { Context } from "../../index.js";
import { deletePortfolioAPI, fetchPortfolio, updatePortfolioAPI } from "../http/portfolioAPI.js";

const PopupPortfolio = observer((props) => {
  const [name, setName] = useState(props.info.name);
  const [link, setLink] = useState(props.info.link);

  const { teamPortfolio } = useContext(Context);

  const updatePortfolio = async () => {
    const formData = new FormData();
    formData.append("id", props.info.id);
    formData.append("name", name);
    formData.append("link", link);

    if (!props.info.id || !name || !link) {
      window.alert("Залишати пусті поля не можна");
    } else {
      const confirmDel = window.confirm("Точно бажаєте зберегти зміни?");
      if (confirmDel) {
        await updatePortfolioAPI(formData).then(() => {
          teamPortfolio.setReloadPortfolio(!teamPortfolio.ReloadPortfolio);
        });
      }
    }
  };

  const deletePortfolio = async () => {
    const confirmDel = window.confirm("Ви дійсно хочете видалити портфоліо?");
    if (confirmDel) {
      await deletePortfolioAPI(props.info.id);
      await fetchPortfolio().then((data) => teamPortfolio.setPortfolio(data));
      props.onHide();
    }
  };

  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" size="lg">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Портфоліо</Modal.Title>
      </Modal.Header>
      <Modal.Body className="grid-example">
        <Container>
          <Row style={{ maxHeight: "300px" }}>
            <Col xs={12} md={3}>
              <Image
                style={{
                  width: "150px",
                  height: "200px",
                }}
                src={API_PHOTO + props.info.img}
              />
            </Col>
            <Col xs={6} md={8}>
              <Form.Control className="mt-2" type="text" value={name} onChange={(e) => setName(e.target.value)} />
              <Form.Control className="mt-2" type="text" value={link} onChange={(e) => setLink(e.target.value)} />
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={() => deletePortfolio()}>
          Видалити
        </Button>
        <Button variant="warning" onClick={() => updatePortfolio()}>
          Зберегти зміни
        </Button>
        <Button variant="success" onClick={props.onHide}>
          Вийти
        </Button>
      </Modal.Footer>
    </Modal>
  );
});
export default PopupPortfolio;
