import React, { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import { API_PHOTO } from "../utils/consts";
import Form from "react-bootstrap/Form";
import { observer } from "mobx-react-lite";
import { createTeamAPI, deleteTeamAPI, fetchTeam, updateTeamAPI } from "../http/teamAPI";
import { useNavigate } from "react-router-dom";
import { Context } from "../../index.js";
import { createPortfolioAPI } from "../http/portfolioAPI.js";

const PopupCreatePortfolio = observer((props) => {
  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const [file, setFile] = useState(null);
  const { teamPortfolio } = useContext(Context);

  const selectFile = (e) => {
    setFile(e.target.files[0]);
  };

  const createPortfolio = async () => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("link", link);
    formData.append("img", file);

    if (!file || !link || !name) {
      window.alert("Залишати пусті поля не можна");
    } else {
      console.log("createPortfolio");
      props.onHide();

      await createPortfolioAPI(formData).then(() => {
        teamPortfolio.setReloadPortfolio(!teamPortfolio.reloadPortfolio);
      });
    }
  };

  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" size="lg">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Форма для додавання портфоліо</Modal.Title>
      </Modal.Header>
      <Modal.Body className="grid-example">
        <Container>
          <Row style={{ maxHeight: "300px" }}>
            <Col xs={12} md={3}>
              <Form.Control
                size="lg"
                className="mt-3"
                style={{
                  width: "150px",
                  height: "100px",
                }}
                type="file"
                onChange={selectFile}
              />
            </Col>
            <Col xs={6} md={8}>
              <Form.Control
                className="mt-2"
                type="text"
                placeholder="Назва проєкту"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <Form.Control
                className="mt-2"
                type="text"
                placeholder="Посилання на проєкт"
                value={link}
                onChange={(e) => setLink(e.target.value)}
              />
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={() => createPortfolio()}>
          Додати Portfolio
        </Button>
        <Button variant="danger" onClick={props.onHide}>
          Вийти
        </Button>
      </Modal.Footer>
    </Modal>
  );
});
export default PopupCreatePortfolio;
