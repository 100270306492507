import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { Button, Container, Navbar } from "react-bootstrap";
import { useContext, useState } from "react";
import { Context } from "../../index.js";
import { LOGIN_ROUTE } from "../utils/consts.js";
import PopupCreateTeam from "./PopupCreateTeam.js";
import PopupCreatePortfolio from "./PopupCreatePortfolio.js";

const NavBar = observer(() => {
  const [modalTeam, setModalTeam] = useState(false);
  const [modalPortfolio, setModalPortfolio] = useState(false);

  const navigate = useNavigate();
  const { user } = useContext(Context);

  const logOut = () => {
    user.setUser({});
    user.setIsAuth(false);
    localStorage.removeItem("token");
    navigate(LOGIN_ROUTE);
  };

  return (
    <Container>
      <Navbar bg="dark" variant="dark" className="navbar">
        <Container>
          <Navbar.Brand onClick={() => navigate("/")} style={{ color: "white" }}>
            Monarch
          </Navbar.Brand>
          <Button className="btn" variant="outline-light" onClick={() => setModalTeam(true)}>
            createTeam
          </Button>
          <Button className="btn" variant="outline-light" onClick={() => setModalPortfolio(true)}>
            createPortfolio
          </Button>
          <Button className="btn" variant="outline-light" onClick={() => logOut()}>
            Вийти
          </Button>
        </Container>
        <PopupCreateTeam show={modalTeam} onHide={() => setModalTeam(false)} />
        <PopupCreatePortfolio show={modalPortfolio} onHide={() => setModalPortfolio(false)} />
      </Navbar>
    </Container>
  );
});

export default NavBar;
