import React, { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { MANAGE_ROUTE } from "../utils/consts";
import { adminRoutes, publicRoutes } from "../routes";
import { Context } from "../../index.js";
import { observer } from "mobx-react-lite";

const AppRouterAdmin = observer(() => {
  const { user } = useContext(Context);

  return (
    <Routes>
      <Route path="/manage" element={<Navigate to={MANAGE_ROUTE} />} />
      {user.isAuth === true &&
        adminRoutes.map(({ path, Component }) => <Route key={path} path={path} element={Component} exact />)}

      {publicRoutes.map(({ path, Component }) => (
        <Route key={path} path={path} element={Component} exact />
      ))}
    </Routes>
  );
});

export default AppRouterAdmin;
