import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";
import PopupTeam from "./PopupTeam.js";

const Team = observer(({ info }) => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <Container>
      <ListGroup className="mt-2" onClick={() => setModalShow(true)}>
        <ListGroup.Item>
          <Row style={{ maxHeight: "50px" }}>
            <Col xs={1} className="col: 1">
              {info.id}
            </Col>
            <Col> {info.first_name}</Col>
            <Col> {info.last_name}</Col>
            <Col> {info.role}</Col>
            <Col> {info.language}</Col>
          </Row>
        </ListGroup.Item>
      </ListGroup>
      <PopupTeam show={modalShow} info={info} onHide={() => setModalShow(false)} />
    </Container>
  );
});

export default Team;
