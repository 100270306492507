import { Col, Container, Row } from "react-bootstrap";
import NavBar from "../components/NavBar";
import { Form } from "react-router-dom";
import LeftBar from "../components/LeftBar";
import Team from "../components/Team";
import Portfolio from "../components/Portfolio";
import { useContext, useEffect } from "react";
import { Context } from "../../index.js";
import { observer } from "mobx-react-lite";
import { fetchTeam } from "../http/teamAPI.js";

const MainAdmin = observer(() => {
  const { teamPortfolio } = useContext(Context);

  //console.log(teamPortfolio.team);

  return (
    <Container className=" mainpageadmin">
      <NavBar />
      <Container className="mt-4  mainpageadmin">
        <Row>
          <Col xs={3}>
            <LeftBar />
          </Col>

          <Col xs={9}>
            <Container style={{ overflowY: "scroll", height: "450px" }}>
              {teamPortfolio.buttonLeftBar === "Team" &&
                teamPortfolio.team.map((info) => {
                  return <Team key={info.id} info={info} />;
                })}
              {teamPortfolio.buttonLeftBar === "Portfolio" &&
                teamPortfolio.portfolio.map((info) => {
                  return <Portfolio key={info.id} info={info} />;
                })}
            </Container>
          </Col>
        </Row>
      </Container>
    </Container>
  );
});
export default MainAdmin;
