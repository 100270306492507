import { useContext } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { Context } from "../../index.js";
import { observer } from "mobx-react-lite";

const LeftBar = observer(() => {
  const { teamPortfolio } = useContext(Context);

  const clickButton = (ch) => {
    teamPortfolio.setButtonLeftBar(ch);
  };
  return (
    <ListGroup variant="flush">
      <ListGroup.Item className="mt-3" variant="dark" onClick={() => clickButton("Team")}>
        Team
      </ListGroup.Item>
      <ListGroup.Item className="mt-3" variant="dark" onClick={() => clickButton("Portfolio")}>
        Portfolio
      </ListGroup.Item>
    </ListGroup>
  );
});

export default LeftBar;
