import { makeAutoObservable } from "mobx";

export default class TeamPortfolioStore {
  constructor() {
    this._team = [];
    this._portfolio = [];
    this._buttonLeftBar = "Team";
    this._reloadTeam = false;
    this._reloadPortfolio = false;
    makeAutoObservable(this);
  }

  setTeam(team) {
    this._team = team;
  }

  setPortfolio(portfolio) {
    this._portfolio = portfolio;
  }

  setButtonLeftBar(nameComponents) {
    this._buttonLeftBar = nameComponents;
  }
  setReloadTeam(bool) {
    this._reloadTeam = bool;
  }
  setReloadPortfolio(bool) {
    this._reloadPortfolio = bool;
  }

  get team() {
    return this._team;
  }

  get portfolio() {
    return this._portfolio;
  }

  get buttonLeftBar() {
    return this._buttonLeftBar;
  }

  get reloadTeam() {
    return this._reloadTeam;
  }

  get reloadPortfolio() {
    return this._reloadPortfolio;
  }
}
