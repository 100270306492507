import Portfolio from "./components/Portfolio";
import Team from "./components/Team";
import Auth from "./pages/Auth";
import MainAdmin from "./pages/MainAdmin";
import {
  LOGIN_ROUTE,
  MAIN_ROUTE,
  PORTFOLIO_ROUTE,
  TEAM_ROUTE,
} from "./utils/consts";

export const publicRoutes = [
  {
    path: LOGIN_ROUTE,
    Component: <Auth />,
  },
];
export const adminRoutes = [
  {
    path: MAIN_ROUTE,
    Component: <MainAdmin />,
  },
  {
    path: TEAM_ROUTE,
    Component: <Team />,
  },
  {
    path: PORTFOLIO_ROUTE,
    Component: <Portfolio />,
  },
];
