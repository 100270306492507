import React, { useState, useContext } from "react";
import { Context } from "../../index.js";

import { Container, Form, Card } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { MAIN_ROUTE } from "../utils/consts.js";
import { login } from "../http/userAPI.js";

const Auth = observer(() => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { user } = useContext(Context);
  const navigate = useNavigate();

  const click = async () => {
    try {
      let data = await login(email, password);
      user.setUser(data);
      user.setIsAuth(true);
      //console.log(data);
      navigate(MAIN_ROUTE);
    } catch (e) {
      alert(e.response.data.message);
    }
  };

  const location = useLocation();
  console.log(location);
  return (
    <Container className="d-flex justify-content-center align-items-center" style={{ height: window.innerHeight - 54 }}>
      <Card style={{ width: 600 }} className="p-5">
        <h2 className="m-auto" style={{ color: "white" }}>
          Авторизація
        </h2>
        <Form.Group>
          <Form.Control
            className="mt-3"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Введіть логін..."
          />
          <Form.Control
            className="mt-3"
            value={password}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Введіть пароль..."
          />
          <Form.Group className="d-flex justify-content-center mt-3 pl-3 pr-10">
            <Button variant={"outline-success"} onClick={() => click()}>
              Ввійти
            </Button>
          </Form.Group>
        </Form.Group>
      </Card>
    </Container>
  );
});

export default Auth;
