import { $authHost, $host } from "./index";

export const fetchPortfolio = async () => {
  const { data } = await $host.get("api/portfolio");

  return data;
};

export const deletePortfolioAPI = async (id) => {
  const { data } = await $authHost.delete("api/portfolio/" + id);
  return data;
};

export const createPortfolioAPI = async (info) => {
  const { data } = await $authHost.post("/api/portfolio", info);
  return data;
};

export const updatePortfolioAPI = async (info) => {
  const { data } = await $authHost.put("/api/portfolio/update", info);
  console.log("function API");
  return data;
};
