import { BrowserRouter, useLocation } from "react-router-dom";
import AppRouterAdmin from "./components/AppRouterAdmin.js";
import NavBar from "./components/NavBar";
import { observer } from "mobx-react-lite";
import { useContext, useEffect } from "react";
import { Context } from "../index.js";
import { check } from "./http/userAPI.js";
import { LOGIN_ROUTE, MAIN_ROUTE } from "./utils/consts.js";
import { fetchTeam } from "./http/teamAPI.js";
import { fetchPortfolio } from "./http/portfolioAPI.js";

const Admin = observer(() => {
  const { user } = useContext(Context);
  const { teamPortfolio } = useContext(Context);

  const location = useLocation();

  useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      check().then((data) => {
        user.setUser(data);
        user.setIsAuth(true);
      });
    }
  }, []);

  useEffect(() => {
    fetchTeam().then((data) => {
      teamPortfolio.setTeam(data);
    });
    fetchPortfolio().then((data) => {
      teamPortfolio.setPortfolio(data);
    });
  }, [teamPortfolio.reloadTeam, teamPortfolio.reloadPortfolio]);

  // {location.pathname === MAIN_ROUTE ? <NavBar /> : <></>}
  return <>{location.pathname === LOGIN_ROUTE || MAIN_ROUTE ? <AppRouterAdmin /> : <></>}</>;
});

export default Admin;
