import { observer } from "mobx-react-lite";
import { useContext, useState } from "react";
import { Button, Col, Image, Row } from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";
import { API_PHOTO } from "../utils/consts";
import { Context } from "../../index.js";
import { deletePortfolioAPI, fetchPortfolio } from "../http/portfolioAPI";
import PopupPortfolio from "./PopupPortfolio.js";

const Portfolio = observer(({ info }) => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <ListGroup className="mt-2" onClick={() => setModalShow(true)}>
        <ListGroup.Item>
          <Row style={{ maxHeight: "80px" }}>
            <Col xs={1}> {info.id}</Col>
            <Col>
              <Image
                style={{
                  width: "60px",
                  height: "80px",
                }}
                src={API_PHOTO + info.img}
              />
            </Col>
            <Col> {info.name}</Col>
            <Col> {info.link}</Col>
          </Row>
        </ListGroup.Item>
      </ListGroup>
      <PopupPortfolio show={modalShow} info={info} onHide={() => setModalShow(false)} />
    </>
  );
});

export default Portfolio;
